import * as types from "../types";
import Cookies from "js-cookie";

export default function authActions(state, dispatch) {
  return {
    setUser: (user) => {
      Cookies.set("@onl-tenants", JSON.stringify(user), {
        expires: new Date(new Date().getTime() + user.expiresIn * 1000),
      });
      dispatch({
        type: types.SET_USER,
        user,
      });
    },
    getUser: () => {
      const user = Cookies.get("@onl-tenants");

      user &&
        dispatch({
          type: types.SET_USER,
          user: JSON.parse(user),
        });

      dispatch({ type: types.USER_LOADED });
    },
    removeUser: () => {
      Cookies.remove("@onl-tenants");
      dispatch({ type: types.REMOVE_USER });
    },
  };
}
