const cache = {
  get: (key) => key && localStorage.getItem(key),
  remove: (key = null) => {
    if (!key) localStorage.clear();
    else localStorage.removeItem(key);
  },
  set: (key, value) =>
    key &&
    localStorage.setItem(
      key,
      (typeof value === "string" && value) || JSON.stringify(value)
    ),
};

export default cache;
