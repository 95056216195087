import { useState, useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  Select,
  Alert,
  message,
  DatePicker,
  Modal,
  Button,
} from "antd";
import { AimOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import useApp from "../../hooks/useApp";
import useLocation from "../../hooks/useLocation";

export default function AddOrganization({
  visible,
  setVisible,
  organizations,
}) {
  const app = useApp();
  const location = useLocation();

  const [form] = Form.useForm();
  const provinceId = Form.useWatch("provinceId", form);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (location.location?.latitude) {
      form.setFieldValue(
        "coordinates",
        `${location.location.latitude}, ${location.location.longitude}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.location]);

  const onOk = async () => {
    try {
      await form.validateFields();

      organizations.actions.add(form.getFieldsValue(), {
        setIsLoading,
        setShowAddModal: setVisible,
        form,
      });
    } catch (errorInfo) {
      console.log(errorInfo);
    }
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf("day");
  };

  const onFinishFailed = (errorInfo) => {
    message.error(errorInfo);
    console.log("Failed:", errorInfo);
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="Inscription à l'ONL"
      open={visible}
      onOk={onOk}
      okText="Ajouter"
      cancelText="Annuler"
      okButtonProps={{
        loading: isLoading,
        htmlType: "submit",
      }}
      onCancel={onCancel}
      width={700}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Form
          name="basic"
          form={form}
          style={{
            //   display: "flex",
            width: "100%",
            maxWidth: "300px",
          }}
          onFinish={onOk}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          disabled={isLoading}
        >
          <Form.Item
            label="Date d'enregistrement"
            name="registeredAt"
            rules={[
              {
                required: true,
                message: "Entrez la date de création de l'organization",
              },
            ]}
          >
            <DatePicker disabledDate={disabledDate} />
          </Form.Item>

          <Form.Item
            label="Nom de l'établissement"
            name="name"
            rules={[
              { required: true, message: "Entrez le nom de l'organization" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Numéro de téléphone"
            name="phone"
            rules={[
              { required: true, message: "Entrez votre numéro de téléphone" },
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              controls={false}
              maxLength={9}
              autoComplete="tel"
            />
          </Form.Item>

          <Form.Item label="Email" name="email">
            <Input />
          </Form.Item>

          <Form.Item
            label="Préfecture"
            name="provinceId"
            rules={[
              { required: true, message: "Veuillez choisir une préfecture" },
            ]}
          >
            <Select
              showSearch
              placeholder="Choisir la préfecture"
              optionFilterProp="children"
              filterOption={filterOption}
              options={app.provinces.map((province) => ({
                value: province.id,
                label: province?.name,
              }))}
            />
          </Form.Item>

          {provinceId === 1306 && (
            <Form.Item
              label="Commune"
              name="communeId"
              rules={[
                { required: true, message: "Veuillez choisir une commune" },
              ]}
            >
              <Select
                showSearch
                placeholder="Choisir la commune"
                optionFilterProp="children"
                filterOption={filterOption}
                options={app.communes.map((commune) => ({
                  value: commune.id,
                  label: commune?.name,
                }))}
              />
            </Form.Item>
          )}

          <Form.Item
            label="Quartier"
            name="address"
            rules={[
              {
                required: true,
                message: "Entrez le quartier de l'organization",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Site web" name="website">
            <Input placeholder="https://www.siteweb.com" />
          </Form.Item>

          <Form.Item
            label="Position Géographique (Longitude et Latitude)"
            name="coordinates"
          >
            <Input
              placeholder="9.xxxxxxxxxx, -13.xxxxxxxxxxxx"
              suffix={
                <Button
                  loading={location.loading}
                  onClick={() => {
                    location.actions.getCurrentLocation();
                  }}
                  icon={<AimOutlined />}
                />
              }
            />
          </Form.Item>

          <Form.Item
            label="Catégorie"
            name="categoryId"
            rules={[
              { required: true, message: "Veuillez choisir une catégorie" },
            ]}
          >
            <Select
              placeholder="Choisir une catégorie"
              options={app.categories.map((category) => ({
                value: category.id,
                label: category?.name,
              }))}
            />
          </Form.Item>

          {organizations.formError && (
            <Form.Item>
              <Alert
                message={organizations.formError}
                type="error"
                closable
                onClose={() => {
                  organizations.actions.setFormError("");
                }}
              />
            </Form.Item>
          )}
        </Form>
      </div>
    </Modal>
  );
}
