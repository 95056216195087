import React from "react";
import { Routes, Route } from "react-router-dom";
import LoadingPage from "./components/Loading";
// import Login from "./pages/Auth/Login";
import Form from "./pages/Form/";
// import ResetPassword from "./pages/Auth/ResetPassword";
// import HomeLayout from "./components/layout/MainLayout";
// import AuthLayout from "./components/layout/AuthLayout";
import PageNotFound from "./components/PageNotFound";
// import sidebar from "./config/sidebar";
import useAuth from "./hooks/useAuth";

function Router() {
  // const { user, userLoaded, ...auth } = useAuth();
  const { userLoaded } = useAuth();

  if (!userLoaded) return <LoadingPage />;

  return (
    <Routes>
      {/* Form */}
      <Route path="/" element={<Form />} />

      {/* Auth Routes */}
      {/* <Route element={<AuthLayout user={user} />}>
        <Route path="/" element={<Login />} />
        <Route path="connexion" element={<Login />} />
        <Route path="changer-mot-de-passe" element={<ResetPassword />} />
      </Route> */}

      {/* Protected Routes */}
      {/* <Route element={<HomeLayout user={user} logOut={auth.actions.logOut} />}>
        {sidebar
          .filter(function filterRecursive(item) {
            if (!user.userGroups) return true;
            return item.children
              ? (item.children = item.children.filter(filterRecursive)).length
              : !item.allow || item.allow.includes(user?.roleId);
          })
          .map(function mapRecursive({ name, path, component, children }) {
            return children ? (
              children.map(mapRecursive)
            ) : (
              <Route key={name} path={path} element={component} />
            );
          })}
      </Route> */}

      {/* Page Not Found */}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default Router;
