export const auth = {
  user: {},
  userLoaded: false,
};

export const app = {
  roles: [],
  provinces: [],
  communes: [],
  organizationStatuses: [],
  categories: [],
};
