import { app } from "../initialState";
import * as types from "../types";

const appReducer = (state = app, action) => {
  switch (action.type) {
    case types.SET_ROLES:
      return {
        ...state,
        roles: action.roles,
      };
    case types.SET_PROVINCES:
      return {
        ...state,
        provinces: action.provinces,
      };
    case types.SET_COMMUNES:
      return {
        ...state,
        communes: action.communes,
      };
    case types.SET_ORGANIZATION_STATUSES:
      return {
        ...state,
        organizationStatuses: action.organizationStatuses,
      };
    case types.SET_CATEGORIES:
      return {
        ...state,
        categories: action.categories,
      };
    default:
      return state;
  }
};

export default appReducer;
