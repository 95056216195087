import { useStore } from "../store";

const { REACT_APP_BACKEND_ENDPOINT: BACKEND_ENDPOINT } = process.env;
export function useApi() {
  const {
    auth: { user = {} },
  } = useStore();

  /**
   *
   * @param baseUrl
   * @param method
   * @param endpoint
   * @param params
   * @returns Promise
   */
  const getRequest = async ({ method = "GET", endpoint, params, baseUrl }) => {
    let url = (baseUrl || BACKEND_ENDPOINT) + endpoint;

    const options = {
      headers: {
        "x-onl-access-token": `${user?.accessToken}` || null,
      },
      method,
    };

    if (params)
      if (["POST", "PUT"].includes(method)) {
        options.headers["Content-Type"] = "application/json";
        options.body = JSON.stringify(params);
      } else {
        const queryString = Object.keys(params)
          .map((key) => key + "=" + params[key])
          .join("&");
        url += "?" + queryString;
      }

    return fetch(url, options)
      .then(async (res) => ({
        status: res.status,
        data: await res.json(),
      }))
      .then(({ status, data }) => {
        if (status !== 200) {
          console.log({ status, data });
          const error = new Error(`${data?.message}`);
          error.code = data?.code;
          error.status = status;
          error.name = data?.error;
          throw error;
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };

  return { getRequest };
}

function getCookie(cName) {
  const name = cName + "=";
  const cDecoded = decodeURIComponent(document.cookie); //to be careful
  const cArr = cDecoded.split("; ");
  let res;
  cArr.forEach((val) => {
    if (val.indexOf(name) === 0) res = val.substring(name.length);
  });
  return res;
}

window.getCookie = getCookie;
