export const SET_USER = "SET_USER";
export const USER_LOADED = "USER_LOADED";
export const REMOVE_USER = "REMOVE_USER";

// APP
export const SET_ROLES = "SET_ROLES";
export const SET_PROVINCES = "SET_PROVINCES";
export const SET_COMMUNES = "SET_COMMUNES";
export const SET_ORGANIZATION_STATUSES = "SET_ORGANIZATION_STATUSES";
export const SET_CATEGORIES = "SET_CATEGORIES";
