import * as types from "../types";

export default function appActions(state, dispatch) {
  return {
    setRoles: (roles) => {
      dispatch({
        type: types.SET_ROLES,
        roles,
      });
    },
    setProvinces: (provinces) => {
      dispatch({
        type: types.SET_PROVINCES,
        provinces,
      });
    },
    setCommunes: (communes) => {
      dispatch({
        type: types.SET_COMMUNES,
        communes,
      });
    },
    setOrganizationStatuses: (organizationStatuses) => {
      dispatch({
        type: types.SET_ORGANIZATION_STATUSES,
        organizationStatuses,
      });
    },
    setCategories: (categories) => {
      dispatch({
        type: types.SET_CATEGORIES,
        categories,
      });
    },
  };
}
