import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../store";
import { useApi } from "../api";
import cache from "../helpers/cache";

function useAuth() {
  const { getRequest } = useApi();
  const [authError, setAuthError] = useState("");
  const navigate = useNavigate();

  const {
    auth: { user, userLoaded },
    actions: { getUser, removeUser, setUser },
  } = useStore();

  useEffect(() => {
    if (!userLoaded) getUser();

    return () => {};
  }, [userLoaded, getUser]);

  const login = ({ phone, password }, { setIsLoading }) => {
    cache.remove();
    setIsLoading(true);
    if (String(phone).length !== 9 || !Number(phone)) {
      setAuthError("Le numéro de téléphone n'est pas valides");
      setIsLoading(false);

      return;
    }

    if (
      !password.length ||
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@#$%^&*()\-_+=]{8,}$/.test(
        password
      )
    ) {
      setAuthError("Le mot de passe n'est pas valide");
      setIsLoading(false);

      return;
    }

    getRequest({
      method: "POST",
      endpoint: "auth/login",
      params: { phone, password },
    })
      .then((data) => {
        if (data?.resetPassword) {
          setUser({ phone: data?.phone });
          cache.set("phone", data?.phone);
          navigate("/changer-mot-de-passe");
        } else setUser(data);
      })
      .catch((err) => {
        console.error(err);
        setAuthError(err?.message || "Erreur lors de la connexion");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const resetPassword = (
    { password, newPassword, newPasswordConfirmation },
    { setIsLoading }
  ) => {
    setIsLoading(true);

    if (
      !newPassword.length ||
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@#$%^&*()\-_+=]{8,}$/.test(
        newPassword
      )
    ) {
      setAuthError(
        "Votre nouveau mot de passe n'est pas valide. Il doit contenir au moins: \n - Une lettre majiscule \n - Une lettre minuscule \n - Un chiffre \n Et long de 8 caractères"
      );
      setIsLoading(false);
      return;
    }

    if (newPassword !== newPasswordConfirmation) {
      setAuthError("Vos deux mots de passe ne sont pas identiques.");
      setIsLoading(false);
      return;
    }

    const phone = user?.phone || cache.get("phone");

    getRequest({
      method: "POST",
      endpoint: "auth/resetPassword",
      params: { phone, password, newPassword, newPasswordConfirmation },
    })
      .then((data) => {
        setUser(data);
      })
      .catch((err) => {
        console.error(err);
        setAuthError(err?.message || "Erreur lors de la connexion");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const refresh = () => {
    getRequest({
      endpoint: "auth/getUser",
    })
      .then((data) => {
        if (data && !data.id) removeUser();
        else setUser({ ...user, ...data });
      })
      .catch((err) => {
        console.error(err);
        setAuthError(err?.message || "Erreur lors de la connexion");
      });
  };

  const logOut = () => {
    removeUser();
  };

  return {
    user,
    userLoaded,
    authError,
    actions: {
      login,
      logOut,
      setAuthError,
      refresh,
      resetPassword,
    },
  };
}

export default useAuth;
