import { useEffect, useState } from "react";
import { message } from "antd";
import { useApi } from "../api";
import { useStore } from "../store";
import cache from "../helpers/cache";

function useApp() {
  const {
    app: { roles, provinces, communes, organizationStatuses },
    actions: { setProvinces, setCommunes },
  } = useStore();

  const [categories, setCategories] = useState([]);
  const { getRequest } = useApi({});

  useEffect(() => {
    if (!categories.length) getCategories();
    if (!provinces.length) getProvinces();
    if (!communes.length) getCommunes();
    // if (cache.get("@onl:roles")) {
    //   setRoles(JSON.parse(cache.get("@onl:roles")));
    // } else getRoles();
    // if (cache.get("@onl:categories")) {
    //   setCategories(JSON.parse(cache.get("@onl:categories")));
    // } else getCategories();
    // if (cache.get("@onl:provinces")) {
    //   setProvinces(JSON.parse(cache.get("@onl:provinces")));
    // } else getProvinces();
    // if (cache.get("@onl:communes")) {
    //   setCommunes(JSON.parse(cache.get("@onl:communes")));
    // } else getCommunes();
    // if (cache.get("@onl:organizationStatuses")) {
    //   setOrganizationStatuses(
    //     JSON.parse(cache.get("@onl:organizationStatuses"))
    //   );
    // } else getOrganizationStatuses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const getRoles = () => {
  //   getRequest({
  //     endpoint: "app/getRoles",
  //   })
  //     .then((results) => {
  //       cache.set("@onl:roles", results);
  //       setRoles(results);
  //     })
  //     .catch((err) => {
  //       message.error("Impossible d'obtenir les roles", 0);
  //     });
  // };

  const getProvinces = () => {
    getRequest({
      endpoint: "app/getProvinces",
    })
      .then((results) => {
        cache.set("@onl:provinces", results);
        setProvinces(results);
      })
      .catch((err) => {
        message.error("Impossible d'obtenir les préfectures");
      });
  };

  const getCategories = () => {
    getRequest({
      endpoint: "app/getCategories",
    })
      .then((results) => {
        // cache.set("@onl:categories", results);
        setCategories(results);
      })
      .catch((err) => {
        message.error("Impossible d'obtenir les catégories", 5);
      });
  };

  const getCommunes = () => {
    getRequest({
      endpoint: "app/getCommunes",
    })
      .then((results) => {
        cache.set("@onl:communes", results);
        setCommunes(results);
      })
      .catch((err) => {
        message.error("Impossible d'obtenir les communes");
      });
  };

  // const getOrganizationStatuses = () => {
  //   getRequest({
  //     endpoint: "app/getOrganizationStatuses",
  //   })
  //     .then((results) => {
  //       cache.set("@onl:organizationStatuses", results);
  //       setOrganizationStatuses(results);
  //     })
  //     .catch((err) => {
  //       message.error("Impossible d'obtenir les status des loisirs", 0);
  //     });
  // };

  return {
    roles,
    provinces,
    communes,
    organizationStatuses,
    categories,
  };
}

export default useApp;
