import { useState } from "react";
import { Button, Alert } from "antd";
import logo from "../../assets/logo.png";
import Add from "./Add";
import useOrganizations from "../../hooks/useOrganizations";

export default function AddOrganization() {
  const organizations = useOrganizations();

  const [visible, setVisible] = useState(false);

  const DescriptionNode = ({ onlId }) => (
    <div style={{ textAlign: "left" }}>
      <div>
        <span>
          Nous avons reçu votre demande. Nous vous contacterons si nous avons
          besoin de plus d'informations
        </span>
      </div>
      <br />
      <div>
        Vous allez recevoir votre numero d'immatriculation par{" "}
        <strong>SMS</strong> ou <strong>EMAIL</strong> lorsque nous aurons
        validé vos informations!
        <br />
        <br />
        Veuillez le conserver pour toute communication future avec nous.
      </div>
    </div>
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        gap: "2rem", // Add some gap between items
      }}
    >
      <Add
        visible={visible}
        setVisible={setVisible}
        organizations={organizations}
      />
      <div
        style={{
          textAlign: "center",
          maxWidth: "100%",
        }}
      >
        <img
          src={logo}
          alt="ONL"
          style={{
            width: "100%",
            maxWidth: "300px",
          }}
        />
      </div>
      {organizations.showSuccess && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
            width: "40%",
          }}
        >
          {/* <Typography.Paragraph>
          To be, or not to be, that is a question: Whether it is nobler in the
          mind to suffer. The slings and arrows of outrageous fortune Or to take
          arms against a sea of troubles, And by opposing end them? To die: to
          sleep; No more; and by a sleep to say we end The heart-ache and the
          thousand natural shocks That flesh is heir to, 'tis a consummation
          Devoutly to be wish'd. To die, to sleep To sleep- perchance to dream:
          ay, there's the rub! For in that sleep of death what dreams may come
          When we have shuffled off this mortal coil, Must give us pause. There
          's the respect That makes calamity of so long life--William
          Shakespeare
        </Typography.Paragraph> */}
          <Alert
            message={
              <span>
                <strong>Vous avez été inscrit avec succès</strong>
              </span>
            }
            description={<DescriptionNode onlId={organizations.showSuccess} />}
            type="success"
            showIcon
            closable
          />
        </div>
      )}

      <div
        style={{
          width: "100%", // Make it the same width as the logo
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          type="primary"
          htmlType="submit"
          onClick={() => setVisible(true)}
        >
          S'enregistrer
        </Button>
      </div>
    </div>
  );
}
