import { auth } from "../initialState";
import * as types from "../types";

const authReducer = (state = auth, action) => {
  switch (action.type) {
    case types.SET_USER:
      return {
        ...state,
        user: action.user,
      };
    case types.USER_LOADED:
      return {
        ...state,
        userLoaded: true,
      };
    case types.REMOVE_USER:
      return {
        ...state,
        user: {},
      };
    default:
      return state;
  }
};

export default authReducer;
