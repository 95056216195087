import { useState, useEffect } from "react";

const useLocation = () => {
  const [location, setLocation] = useState({
    latitude: null,
    longitude: null,
    error: null,
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCurrentLocation();
  }, []);

  const getCurrentLocation = () => {
    setLoading(true);
    // Check if Geolocation API is supported
    if (!navigator.geolocation) {
      setLocation((prevState) => ({
        ...prevState,
        error: "Geolocation is not supported by your browser.",
      }));
      setLoading(false);
      return;
    }

    // Define a function to call when the user's location is successfully retrieved
    const success = (position) => {
      setLocation({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        error: null,
      });
      setLoading(false);
    };

    // Define a function to call when there is an error retrieving the user's location
    const error = () => {
      setLocation((prevState) => ({
        ...prevState,
        error: "Unable to retrieve your location",
      }));
      setLoading(false);
    };

    // Request the user's current position
    navigator.geolocation.getCurrentPosition(success, error, {
      enableHighAccuracy: true,
    });
  };

  return {
    location: {
      latitude: location.latitude,
      longitude: location.longitude,
    },
    loading: loading,
    error: location.error || null,
    actions: {
      getCurrentLocation,
    },
  };

  //   return (
  //     <div>
  //       <h2>Your Location</h2>
  //       {location.error ? (
  //         <p>Error: {location.error}</p>
  //       ) : (
  //         <p>
  //           Latitude: {location.latitude}, Longitude: {location.longitude}
  //         </p>
  //       )}
  //     </div>
  //   );
};

export default useLocation;
