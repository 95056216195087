import { useState } from "react";
import { message } from "antd";
import { useApi } from "../api";
import sanatizeObject from "../helpers/sanatizeObject";

const websitePattern =
  "^(https?:\\/\\/)?([\\da-z.-]+)\\.([a-z.]{2,6})([\\/\\w .-]*)*\\/?$";
const websiteRegex = new RegExp(websitePattern);

const coordinatesRegex = /^-?\d+\.\d+,\s*-?\d+\.\d+$/;

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

function useOrganizations() {
  const { getRequest } = useApi();
  const [formError, setFormError] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState({});

  // useEffect(() => {
  //   if (!organizations.length) getOrganizations();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const getOrganizations = (filters = {}) => {
    setIsLoading(true);
    getRequest({
      endpoint: "organizations/get",
      params: sanatizeObject(filters),
    })
      .then((organizations) => {
        setOrganizations(organizations);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const add = (
    {
      name,
      phone,
      email,
      address,
      website,
      coordinates,
      categoryId,
      provinceId,
      registeredAt,
      communeId,
    },
    { setIsLoading, setShowAddModal, form }
  ) => {
    setFormError("");
    if (website && !websiteRegex.test(website)) {
      setFormError("Veuillez entrer un site web valide!");
      setIsLoading(false);
      return;
    }

    if (coordinates && !coordinatesRegex.test(coordinates)) {
      setFormError("Veuillez entrer des coordonnées valides!");
      setIsLoading(false);
      return;
    }

    if (email && !emailRegex.test(email)) {
      setFormError("Veuillez entrer un email valide!");
      setIsLoading(false);
      return;
    }

    if (String(phone).length !== 9 || !Number(phone)) {
      setFormError("Le numéro de téléphone n'est pas valide");
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    getRequest({
      method: "POST",
      endpoint: "organizations/addunauth",
      params: {
        name,
        phone,
        email,
        address,
        website,
        registeredAt: new Date(registeredAt),
        communeId,
        coordinates,
        categoryId,
        provinceId,
      },
    })
      .then(({ onlId }) => {
        message.success(`Vous avez ajouté une nouveau loisir <${name}>!`, 5);
        setShowAddModal(false);
        setShowSuccess(true);
        form && form.resetFields();
        // getOrganizations();
      })
      .catch((err) => {
        console.error(err);
        setFormError(err?.message || "Erreur lors de la l'ajout d'un loisir");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const edit = (
    {
      id,
      name,
      phone,
      address,
      website,
      coordinates,
      categoryId,
      provinceId,
      registeredAt,
      communeId,
      statusId,
    },
    { setIsLoading, setShowEditModal }
  ) => {
    setFormError("");
    setIsLoading(true);

    if (website && !websiteRegex.test(website)) {
      setFormError("Veuillez entrer un site web valide!");
      setIsLoading(false);
      return;
    }

    // if (coordinates && !coordinatesRegex.test(coordinates)) {
    //   setFormError("Veuillez entrer des coordonnées valides!");
    //   setIsLoading(false);
    //   return;
    // }

    if (String(phone).length !== 9 || !Number(phone)) {
      setFormError("Le numéro de téléphone n'est pas valide");
      setIsLoading(false);
      return;
    }

    getRequest({
      method: "PUT",
      endpoint: `organizations/${id}`,
      params: {
        name,
        phone,
        address,
        website,
        categoryId,
        provinceId,
        communeId,
        registeredAt,
        statusId,
      },
    })
      .then(() => {
        message.success(`Vous avez modifié le loisir <${name}>!`, 5);
        getOrganizations();
        setShowEditModal(false);
      })
      .catch((err) => {
        console.error(err);
        setFormError(err?.message || "Erreur lors de la l'ajout d'un loisir");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const remove = ({ id, name }) => {
    setLoadingStatus({ [id]: true });
    getRequest({
      method: "DELETE",
      endpoint: `categories/${id}`,
    })
      .then(() => {
        message.success(`Vous avez supprimé ${name}!`, 5);
        getOrganizations();
      })
      .catch((err) => {
        console.error(err);
        setFormError(
          err?.message || "Erreur lors de la suppression d'un utilisateur"
        );
      })
      .finally(() => {
        setLoadingStatus({});
      });
  };

  return {
    formError,
    organizations,
    isLoading,
    loadingStatus,
    showSuccess,
    actions: {
      add,
      setFormError,
      setIsLoading,
      edit,
      remove,
      getOrganizations,
    },
  };
}

export default useOrganizations;
