import { ConfigProvider } from "antd";
import "./App.css";
import Routes from "./Routes";
function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorPrimary: "#11B182",
          // borderRadius: 2,

          // Alias Token
          // colorBgContainer: "#f6ffed",
        },
        components: {
          Button: {
            // Seed Token
            colorPrimary: "#11B182",
            colorLink: "#11B182",
            colorLinkHover: "#52C41A",
            // borderRadius: 2,

            // Alias Token
            colorBgContainer: "#f6ffed",
          },
          Input: {
            colorPrimary: "#11B182",
            activeBorderColor: "#11B182",
            hoverBorderColor: "#11B182",
            colorBgContainer: "#f6ffed",
          },
          InputNumber: {
            colorPrimary: "#11B182",
            activeBorderColor: "#11B182",
            hoverBorderColor: "#11B182",
            colorBgContainer: "#f6ffed",
          },
          Select: {
            colorPrimary: "#11B182",
            activeBorderColor: "#11B182",
            hoverBorderColor: "#11B182",
            colorBgContainer: "#f6ffed",
          },
          DatePicker: {
            colorPrimary: "#11B182",
            activeBorderColor: "#11B182",
            hoverBorderColor: "#11B182",
            colorBgContainer: "#f6ffed",
          },
        },
      }}
    >
      <Routes />;
    </ConfigProvider>
  );
}

export default App;
