function removeEmptyValues(obj) {
  return Object.entries(obj)
    .filter(
      ([key, value]) =>
        value !== "" &&
        value !== null &&
        value !== undefined &&
        (!Array.isArray(value) || value.length !== 0)
    )
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});
}

export default removeEmptyValues;
